<template>
    <div id="app">
        <div class="opera-right" v-if="isElectron">
            <div class="li min" title="最小化" @click="onElectronOperationWindow('min')">
                <i></i>
            </div>
            <div class="li max" title="最大化" @click="onElectronOperationWindow('max');isMax = true" v-if="!isMax">
                <i></i>
            </div>
            <div class="li restoreDown" title="向下还原" @click="onElectronOperationWindow('restoreDown');isMax = false" v-else>
                <i></i>
            </div>
            <div class="li close" title="关闭" @click="onElectronOperationWindow('close')">
                <i></i>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
import { isElectron } from '@/utils'
export default {
    name: 'App',
    data() {
        return {
            isElectron: false,
            isMax: false,
        }
    },
    mounted() {
        this.isElectron = isElectron()
        console.log('isElectron', this.isElectron)
    },
    methods: {
        onElectronOperationWindow(operationType) {
            // ipcMain/ipcRenderer方案 此方案出现preload.js contextBridge undefined
            // if (window.electronAPI && window.electronAPI.operationWindow) {
			// 	window.electronAPI.operationWindow('mainWindow', operationType)
			// }

            // electron remote方案
            const currentOperationWindow = this.$remote.getCurrentWindow() // 当前操作的窗口实例
            switch (operationType) {
                case 'min': // 窗口 最小化
                currentOperationWindow.minimize()
                break
                case 'max': // 窗口 最大化
                currentOperationWindow.maximize()
                break
                case 'restoreDown': // 窗口 向下还原
                currentOperationWindow.unmaximize()
                break
                case 'close': // 窗口 关闭
                currentOperationWindow.close()
                break
            }
        }
    }
}
</script>

<style lang="less" scoped>
    @bgColor: #E8EDF6;
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        .opera-right {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 0 8px 8px;
            overflow: hidden;
            z-index: 1;
            .li {
                display: inline-block;
                text-align: center;
                cursor: pointer;
                width: 40px;
                height: 30px;
                background: @bgColor;
                &:hover {
                    background: darken(@bgColor, 5%); // 颜色加重5%
                }
                i {
                    display: inline-block;
                    vertical-align: top;
                    margin: 8px 0 4px;
                    width: 14px;
                    height: 14px;
                }
                &.min {
                    &:hover {
                        i {
                            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNzE4OTMzODcwMTgyIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjE3MzQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjY2IiBoZWlnaHQ9IjY2NiI+PHBhdGggZD0iTTkyOS44IDUyOC4xSDkzLjVjLTE1LjUgMC0yOC0xMi41LTI4LTI4czEyLjUtMjggMjgtMjhoODM2LjNjMTUuNSAwIDI4IDEyLjUgMjggMjhzLTEyLjUgMjgtMjggMjh6IiBmaWxsPSIjOTk5IiBwLWlkPSIxNzM1Ij48L3BhdGg+PC9zdmc+);
                        }
                    }
                    i {
                        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNzE4OTMzODcwMTgyIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjE3MzQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjY2IiBoZWlnaHQ9IjY2NiI+PHBhdGggZD0iTTkyOS44IDUyOC4xSDkzLjVjLTE1LjUgMC0yOC0xMi41LTI4LTI4czEyLjUtMjggMjgtMjhoODM2LjNjMTUuNSAwIDI4IDEyLjUgMjggMjhzLTEyLjUgMjgtMjggMjh6IiBmaWxsPSIjNjY2IiBwLWlkPSIxNzM1Ij48L3BhdGg+PC9zdmc+) no-repeat center/cover;
                    }
                }
                &.max {
                    &:hover {
                        i {
                            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNzE4OTQ5NzQzODA0IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjE3MzUiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjY2IiBoZWlnaHQ9IjY2NiI+PHBhdGggZD0iTTgxMi4zIDk1OS40SDIxMy43Yy04MS42IDAtMTQ4LTY2LjQtMTQ4LTE0OFYyMTIuOWMwLTgxLjYgNjYuNC0xNDggMTQ4LTE0OGg1OTguNWM4MS42IDAgMTQ4IDY2LjQgMTQ4IDE0OHY1OTguNUM5NjAuMyA4OTMgODkzLjkgOTU5LjQgODEyLjMgOTU5LjR6TTIxMy43IDEyMC45Yy01MC43IDAtOTIgNDEuMy05MiA5MnY1OTguNWMwIDUwLjcgNDEuMyA5MiA5MiA5Mmg1OTguNWM1MC43IDAgOTItNDEuMyA5Mi05MlYyMTIuOWMwLTUwLjctNDEuMy05Mi05Mi05MkgyMTMuN3oiIGZpbGw9IiM5OTkiIHAtaWQ9IjE3MzYiPjwvcGF0aD48L3N2Zz4=);
                        }
                    }
                    i {
                        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNzE4OTQ5NzQzODA0IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjE3MzUiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjY2IiBoZWlnaHQ9IjY2NiI+PHBhdGggZD0iTTgxMi4zIDk1OS40SDIxMy43Yy04MS42IDAtMTQ4LTY2LjQtMTQ4LTE0OFYyMTIuOWMwLTgxLjYgNjYuNC0xNDggMTQ4LTE0OGg1OTguNWM4MS42IDAgMTQ4IDY2LjQgMTQ4IDE0OHY1OTguNUM5NjAuMyA4OTMgODkzLjkgOTU5LjQgODEyLjMgOTU5LjR6TTIxMy43IDEyMC45Yy01MC43IDAtOTIgNDEuMy05MiA5MnY1OTguNWMwIDUwLjcgNDEuMyA5MiA5MiA5Mmg1OTguNWM1MC43IDAgOTItNDEuMyA5Mi05MlYyMTIuOWMwLTUwLjctNDEuMy05Mi05Mi05MkgyMTMuN3oiIGZpbGw9IiM2NjYiIHAtaWQ9IjE3MzYiPjwvcGF0aD48L3N2Zz4=) no-repeat center/cover;
                    }
                }
                &.restoreDown {
                    &:hover {
                        i {
                            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNzE4OTMzOTE1MjIzIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjIzNzYiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjY2IiBoZWlnaHQ9IjY2NiI+PHBhdGggZD0iTTgxMi4yIDY1SDM1MS42Yy03OC4zIDAtMTQyLjUgNjEuMS0xNDcuNyAxMzguMS03NyA1LjEtMTM4LjEgNjkuNC0xMzguMSAxNDcuN3Y0NjAuNmMwIDgxLjYgNjYuNCAxNDggMTQ4IDE0OGg0NjAuNmM3OC4zIDAgMTQyLjUtNjEuMSAxNDcuNy0xMzguMSA3Ny01LjEgMTM4LjEtNjkuNCAxMzguMS0xNDcuN1YyMTNjMC04MS42LTY2LjQtMTQ4LTE0OC0xNDh6IG0tNDUuOCA3NDYuM2MwIDUwLjctNDEuMyA5Mi05MiA5MkgyMTMuOGMtNTAuNyAwLTkyLTQxLjMtOTItOTJWMzUwLjdjMC01MC43IDQxLjMtOTIgOTItOTJoNDYwLjZjNTAuNyAwIDkyIDQxLjMgOTIgOTJ2NDYwLjZ6IG0xMzcuOC0xMzcuN2MwIDQ3LjMtMzUuOCA4Ni4zLTgxLjggOTEuNFYzNTAuN2MwLTgxLjYtNjYuNC0xNDgtMTQ4LTE0OEgyNjAuMmM1LjEtNDUuOSA0NC4yLTgxLjggOTEuNC04MS44aDQ2MC42YzUwLjcgMCA5MiA0MS4zIDkyIDkydjQ2MC43eiIgZmlsbD0iIzk5OSIgcC1pZD0iMjM3NyI+PC9wYXRoPjwvc3ZnPg==);
                        }
                    }
                    i {
                        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNzE4OTMzOTE1MjIzIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjIzNzYiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjY2IiBoZWlnaHQ9IjY2NiI+PHBhdGggZD0iTTgxMi4yIDY1SDM1MS42Yy03OC4zIDAtMTQyLjUgNjEuMS0xNDcuNyAxMzguMS03NyA1LjEtMTM4LjEgNjkuNC0xMzguMSAxNDcuN3Y0NjAuNmMwIDgxLjYgNjYuNCAxNDggMTQ4IDE0OGg0NjAuNmM3OC4zIDAgMTQyLjUtNjEuMSAxNDcuNy0xMzguMSA3Ny01LjEgMTM4LjEtNjkuNCAxMzguMS0xNDcuN1YyMTNjMC04MS42LTY2LjQtMTQ4LTE0OC0xNDh6IG0tNDUuOCA3NDYuM2MwIDUwLjctNDEuMyA5Mi05MiA5MkgyMTMuOGMtNTAuNyAwLTkyLTQxLjMtOTItOTJWMzUwLjdjMC01MC43IDQxLjMtOTIgOTItOTJoNDYwLjZjNTAuNyAwIDkyIDQxLjMgOTIgOTJ2NDYwLjZ6IG0xMzcuOC0xMzcuN2MwIDQ3LjMtMzUuOCA4Ni4zLTgxLjggOTEuNFYzNTAuN2MwLTgxLjYtNjYuNC0xNDgtMTQ4LTE0OEgyNjAuMmM1LjEtNDUuOSA0NC4yLTgxLjggOTEuNC04MS44aDQ2MC42YzUwLjcgMCA5MiA0MS4zIDkyIDkydjQ2MC43eiIgZmlsbD0iIzY2NiIgcC1pZD0iMjM3NyI+PC9wYXRoPjwvc3ZnPg==) no-repeat center/cover;
                    }
                }
                &.close {
                    &:hover {
                        background: red;
                        i {
                            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNzE4OTMzODk4ODQyIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjIwNTQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjY2IiBoZWlnaHQ9IjY2NiI+PHBhdGggZD0iTTk2LjYgOTE1Yy0xMC45LTEwLjktMTAuOS0yOC43IDAtMzkuNkw4ODcgODVjMTAuOS0xMC45IDI4LjctMTAuOSAzOS42IDAgMTAuOSAxMC45IDEwLjkgMjguNyAwIDM5LjZMMTM2LjIgOTE1Yy0xMC45IDEwLjktMjguNiAxMC45LTM5LjYgMHoiIGZpbGw9IiNmZmYiIHAtaWQ9IjIwNTUiPjwvcGF0aD48cGF0aCBkPSJNODg3IDkxNUw5Ni42IDEyNC42Yy0xMC45LTEwLjktMTAuOS0yOC43IDAtMzkuNiAxMC45LTEwLjkgMjguNy0xMC45IDM5LjYgMGw3OTAuNCA3OTAuNGMxMC45IDEwLjkgMTAuOSAyOC43IDAgMzkuNi0xMC45IDEwLjktMjguNiAxMC45LTM5LjYgMHoiIGZpbGw9IiNmZmYiIHAtaWQ9IjIwNTYiPjwvcGF0aD48L3N2Zz4=);
                        }
                    }
                    i {
                        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNzE4OTMzODk4ODQyIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjIwNTQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjY2IiBoZWlnaHQ9IjY2NiI+PHBhdGggZD0iTTk2LjYgOTE1Yy0xMC45LTEwLjktMTAuOS0yOC43IDAtMzkuNkw4ODcgODVjMTAuOS0xMC45IDI4LjctMTAuOSAzOS42IDAgMTAuOSAxMC45IDEwLjkgMjguNyAwIDM5LjZMMTM2LjIgOTE1Yy0xMC45IDEwLjktMjguNiAxMC45LTM5LjYgMHoiIGZpbGw9IiM2NjYiIHAtaWQ9IjIwNTUiPjwvcGF0aD48cGF0aCBkPSJNODg3IDkxNUw5Ni42IDEyNC42Yy0xMC45LTEwLjktMTAuOS0yOC43IDAtMzkuNiAxMC45LTEwLjkgMjguNy0xMC45IDM5LjYgMGw3OTAuNCA3OTAuNGMxMC45IDEwLjkgMTAuOSAyOC43IDAgMzkuNi0xMC45IDEwLjktMjguNiAxMC45LTM5LjYgMHoiIGZpbGw9IiM2NjYiIHAtaWQ9IjIwNTYiPjwvcGF0aD48L3N2Zz4=) no-repeat center/cover;
                    }
                }
            }
        }
    }
</style>
