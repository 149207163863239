import Vue from 'vue'
import VueRouter from 'vue-router'
import { getTokenCookie } from '@/utils/cookie'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import HomeLayout from '@/components/layouts/HomeLayout.vue'

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/home',
    //     name: 'home',
    //     component: () => import('../views/HomePage.vue'),
    // },
    // {
    //     path: '/sfl',
    //     name: 'sfl',
    //     component: () => import('../views/Vue3-SFL.vue'),
    // },
    // {
    //     path: '/',
    //     redirect: '/home',
    // },
    {
        path: '/',
        component: HomeLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('../views/HomePage.vue')
            },
            {
                path: '/sfl',
                name: 'sfl',
                component: () => import('../views/Vue3-SFL.vue')
            },
            {
                path: '/sre',
                name: 'sre',
                component: () => import('../views/Vue2-SRE.vue')
            },
            {
                path: '/sbc',
                name: 'sbc',
                component: () => import('../views/Vue2-SBC.vue')
            },
        ],
    },
    {
        path: '/login',
        component: DefaultLayout,
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('../views/LoginPage.vue')
            },
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes,
})

// 全局前置路由守卫
router.beforeEach((to, from, next) => {
    // eslint-disable-next-line no-constant-condition
    if (getTokenCookie() || to.path === '/login') {
        next()
    } else {
        next('/login')
    }
})

// 防止连续点击多次路由报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push //备份原push方法
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location, resolve, reject) {
    // return originalPush.call(this, location).catch(err => err)
    if (resolve && reject) { // 如果传了回调函数 直接使用
        originalPush.call(this, location, resolve, reject)
    } else { // 如果没有传回调函数 手动添加
        originalPush.call(this, location, () => {}, () => {})
    }
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

export default router
