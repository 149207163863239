/**
 * 判断是否electron环境
 */
export const isElectron = () => {
    // eslint-disable-next-line no-constant-condition
    if (1) { return false } // 暂时写死

    if (typeof process !== 'undefined' && process.type) { // 检查process对象
        return true
    }
    if (navigator.userAgent.includes('Electron') && typeof require !== 'undefined') { // 检查navigator.userAgent属性 检查require方法
        return true
    }
    return false
}