import { defineStore } from 'pinia'
import {
    getTokenCookie,
    setTokenCookie,
    removeTokenCookie,
    getUsersCookie,
    setUsersCookie,
    removeUsersCookie,
} from '@/utils/cookie'

// 用户相关状态 持久化存储cookie
export const useUserStore = defineStore('user', {
    state: () => ({
        token: getTokenCookie() ?? null,
        users: getUsersCookie() ? JSON.parse(getUsersCookie()) : {},
    }),
    getters: {
        getToken() {
            return this.token
        },
        getUsers() {
            return this.users
        },
    },
    actions: {
        setToken(val) {
            this.token = val
            val ? setTokenCookie(val) : removeTokenCookie()
        },
        setUsers(val) {
            this.users = val
            val ? setUsersCookie(JSON.stringify(val)) : removeUsersCookie()
        },
    },
    persist: {
        enabled: false, // 是否开启本地sessionStorage数据缓存
    },
})
